@import "../../utils.scss";
@import "../../style.scss";

.menu-item {
	font-size: 15px;
	font-weight: 300;
	margin: 0 15px;
	color: $grey;
	text-decoration: none;
	@include prefix(transition, all .2s ease-in-out, webkit moz ms o);

	&:hover {
		color: $white;
	}

	&__short-title {
		display: none;
	}

	&__long-title {
		display: inline;
	}

	@media screen and (min-width: 650px) {
		&--large {
			font-size: 20px;
		}
	}

	@media screen and (max-width: 650px) {
		&__short-title {
			display: inline;
		}

		&__long-title {
			display: none;
		}
	}
}
