@import "./style.scss";
@import "./utils.scss";

html {
	scroll-behavior: smooth;
}

body {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    background-color: $black;
}

a {
	color: inherit;
}

* {
	@include noSelection();
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
