@import "../../style.scss";

.item-footer {
	background-color: $grey-dark;
	color: $white;
	padding: 25px;
	z-index: 3;

    &__title {
        font-size: 20px;
		font-weight: 400;

		&:not(&--no-margin) {
			margin-bottom: 10px;
		}
	}

    &__description {
        font-size: 15px;
		font-weight: 300;
	}

	&__subdescription {
        font-size: 13px;
		font-weight: 300;
		color: $grey-light;
		margin-top: 5px;
    }
}
