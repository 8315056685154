@import "../../style.scss";

.footer {
	display: flex;
	align-items: center;
	flex-direction: column;
    color: $grey;
    justify-content: center;
	margin: 20px;

	&__name {
		font-size: 15px;
		font-weight: 300;
		text-decoration: none;
		margin: 10px;
	}

	&__cookies {
		font-size: 10px;
		opacity: 0.75;
	}
}
