@import "../../style.scss";
@import "../../utils.scss";

$image-size: 300px;
$image-margin: 15px;

.item-container {
	display: flex;
	flex-direction: column;
	width: $image-size;
	margin: 35px;
	overflow: hidden;
	@include prefix(border-radius, 3px, moz webkit ms);
	@include prefix(transition, all .2s ease-in-out, webkit moz ms o);

	&:not(&--disabled) {
		cursor: pointer;

		&:hover {
			box-shadow: 0 0 0 2px $white, 0 0 0 6px $black;
		}
	}
}
