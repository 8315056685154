@import "../../utils.scss";
@import "../../style.scss";

.menu {
	display: flex;
	justify-content: flex-end;
	padding: 20px;
	position: fixed;
	top: 0;
	width: 100%;
	right: 0;
	background-color: $black;
	z-index: 4;

	@include prefix(box-shadow, 0px 5px 20px 0px rgba(0, 0, 0, 0.5), webkit moz);
}
