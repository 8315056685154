@import "../../style.scss";

.category-component {
	display: flex;
	flex-direction: column;

	&__title {
		color: $white;
		font-size: 40px;
		margin-left: 30px;
		margin-top: 90px;
		font-weight: 100;
	}

	&__subtitle {
		color: $grey;
		font-size: 20px;
		margin-left: 30px;
		margin-top: 10px;
		font-weight: 300;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
