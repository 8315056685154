@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }
    #{$property}: $value;
}

@mixin noSelection() {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
