@import "../../utils.scss";
@import "../../style.scss";

$image-size: 300px;

.item-preview {
	background-color: $grey-dark;
	width: $image-size;
	height: $image-size;

	&__image {
		display: block;
		width: $image-size;
		height: $image-size;
		overflow: hidden;

		&--blured {
			@include prefix(filter, blur(5px), moz o ms webkit);
		}
	}

	&__overlay {
		display: none;

		&--active {
			position: absolute;
			display: flex;
			background-color: rgba(0, 0, 0, 0.6);
			width: $image-size;
			height: $image-size;
			align-items: center;
			justify-content: center;
			z-index: 1;
			color: $white;
			font-size: 42px;
			font-weight: 300;
			text-align: center;
		}

		&--no-overlay-shadow {
			background-color: transparent;
		}
	}
}
